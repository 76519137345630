import { Avatar } from "@mui/material";
import CustomCarousel from "components/shared/ui/carousel";
import React from "react";
import styles from "./home.module.css";

const Story = () => {
  var items = [
    {
      name: "Shweta Yemdey ",
      profilePicture: "./shweta_yemdey.jpeg",
      organization: "Section head HR, JK cement Panna",
      description:
        "I know Jyoti since long..she  has a dynamic personality as a Coach, Trainer & Mentor. Her interpersonal skills lets her have a quick bonding with people. She is One of the most inspirational lady that I have ever known.",
    },
    {
      name: "Mita Jani",
      profilePicture: "./mita_jani.jpeg",
      organization: "CEO, MVJ Soft, Ahmedabad",
      description:
        "Jyoti is a passionate Person who truly practises what she preaches. Her compassion and love for humanity, makes her a true Mentor. Her courses are beautifully made to inspire people and guide them wonderfully in their life journey.",
    },
    {
      name: "Tahseen Wahdat Wassan",
      profilePicture: "./Tahseen_Wahdat_Wassan.jpeg",
      organization: "Head, Talent Management, Petro Rabigh, UAE",
      description:
        "Jyoti is a person with high energy who uses it to elevate the people she touches through her workshops. If one wants to super motivate their teams for action, Jyoti can do it through her interventions.",
    },
  ];

  return (
    <>
      <div className={styles.story_container}>
        <div className={styles.story_text_container}>
          <div className={styles.story_heading}>STORIES OF IMPACT</div>
          <div className={styles.story_main_heading}>
            What clients say about work that inspire me the most
          </div>
          <div>
            <img src={"./title_underline.png"} alt="underline title img" />
          </div>
        </div>
        <div className={styles.story_option_carousel_container}>
          <CustomCarousel>
            {items.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </CustomCarousel>
        </div>
      </div>
    </>
  );
};

function Item(props) {
  return (
    <div className={styles.story_option_container}>
      <div className={styles.story_option_profile_picture_container}>
        <Avatar
          alt="profile img"
          src={props.item.profilePicture}
          sx={{ width: 145, height: 145 }}
        />
      </div>
      <div className={styles.story_option_text_container}>
        <div className={styles.story_option_text_description}>
          {props.item.description}
        </div>
        <div className={styles.story_option_text_name_org}>
          {props.item.name} - {props.item.organization}
        </div>
      </div>
    </div>
  );
}

export default Story;
