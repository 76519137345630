import React from "react";
import styles from "./contact.module.css";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import CustomButton from "components/shared/form/customButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

const googleRegistrationLink =
  "https://docs.google.com/forms/d/e/1FAIpQLSehVKpofpKmd2gkftJ5VYHawIq9HD4CafGkVxqrkCftEuHm4Q/viewform";

const Contact = () => {
  const openRegistrationForm = () => {
    window.open(googleRegistrationLink, "_blank");
  };

  return (
    <>
      <div className="main-container">
        <div className="title">Contact</div>
        <div>
          <img src={"./title_underline.png"} alt="underline title img" />
        </div>
        <div className={styles.contact_content}>
          <div>
            <div>
              <div className={styles.contact_info_container}>
                <div className={styles.contact_icon}>
                  <PhoneIcon />
                </div>
                <div>+91 83690 07944</div>
              </div>
              <div className={styles.contact_info_container}>
                <div className={styles.contact_icon}>
                  <EmailIcon />
                </div>
                <div>connect@elevatewithjyoti.com</div>
              </div>
              <div className={styles.contact_social_media}>
                <div className={styles.contact_icon}>
                  <FacebookIcon />
                </div>
                <div className={styles.contact_icon}>
                  <TwitterIcon />
                </div>
                <div className={styles.contact_icon}>
                  <YouTubeIcon />
                </div>
                <div className={styles.contact_icon}>
                  <InstagramIcon />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.contact_right_content_info}>
              Are You Ready To Start Designing Your Life?
            </div>
            <div className={styles.contact_right_content_info}>
              <CustomButton
                variant="contained"
                size="medium"
                label={"Sign Up For a Free Demo"}
                onClick={() => openRegistrationForm()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
