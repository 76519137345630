import CustomButton from "components/shared/form/customButton";
import React, { useEffect, useState } from "react";
import styles from "./home.module.css";
import { GET } from "services/apiHelper";
import { getYouTubeVideos } from "services/apiUrlHelper";
import YouTubeIcon from '@mui/icons-material/YouTube';

const MyProgram = () => {
  const [programList, SetProgramList] = useState([]);

  useEffect(() => {
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = () => {
    fetchYouTubeVideos();
  };

  const fetchYouTubeVideos = async () => {
    let res = await GET(getYouTubeVideos());
    let finalRes = await res.json();
    if (
      finalRes &&
      finalRes.hasOwnProperty("items") &&
      finalRes["items"].length
    ) {
      let videoList = [];
      for (let i = 0; i < finalRes["items"].length; i++) {
        if (finalRes["items"][i]["id"]["kind"] === "youtube#video") {
          videoList.push({
            id: finalRes["items"][i]["id"]["videoId"],
            title: finalRes["items"][i]["snippet"]["title"],
            thumbnail: finalRes["items"][i]["snippet"]["thumbnails"]["high"],
          });
        }
      }
      SetProgramList(videoList);
    }
  };
  return (
    <>
      <div className={styles.my_program_container}>
        <div className={styles.my_program_text_container}>
          <div className={styles.my_program_heading}>MY PROGRAMS</div>
          <div className={styles.my_program_main_heading}>
            From team-building to goal setting to energy boosters
          </div>
          <div>
            <img src={"./title_underline.png"} alt="underline title img" />
          </div>
        </div>
        <div className={styles.my_program_video_container}>
          {programList.map((item) => (
            <a href={`https://www.youtube.com/watch?v=${item.id}`} key={item.id} target="_blank" rel="noreferrer">
            <div className={styles.my_program_video_thumbnail}>
              <div className={styles.my_program_youTubeIcon_container}>
                <YouTubeIcon className={styles.my_program_youTubeIcon}/>
              </div>
              <img src={item.thumbnail.url} alt="video thumbnail img" className={styles.youtube_thumbnail}/>
              <div className={styles.my_program_video_thumbnail_text}>
                <div className={styles.my_program_video_thumbnail_title} dangerouslySetInnerHTML={{__html: item.title}}></div>
              </div>
            </div>
            </a>
          ))}
        </div>
        <div className={styles.my_program_button}>
          <CustomButton
            variant="contained"
            size="medium"
            label={"MORE VIDEOS"}
            onClick={() => window.open("https://www.youtube.com/channel/UC7-10vPsSDvvA6iZTZ1PyzQ", "_blank")}
          />
        </div>
      </div>
    </>
  );
};

export default MyProgram;
