import { DialogContent } from "@mui/material";
import React from "react";
import styles from "./registerModalContent.module.css";
import CustomButton from "components/shared/form/customButton";

const googleRegistrationLink = "https://docs.google.com/forms/d/e/1FAIpQLSehVKpofpKmd2gkftJ5VYHawIq9HD4CafGkVxqrkCftEuHm4Q/viewform";

const RegisterModalContent = (props) => {

  const openRegistrationForm = () => {
    window.open(googleRegistrationLink, "_blank");
  }

  return (
    <>
      <DialogContent>
        <div className={styles.register_modal_container}>
          <div className={styles.register_modal_left_content}>
            <img src="./aboutMeJyoti.jpeg" alt="registration left main img" className={styles.registration_modal_left_main_img}/>
          </div>
          <div className={styles.register_modal_right_content}>
            <div className={styles.register_modal_right_content_header}>Are You Ready To Start Designing Your Life?</div>
            <div className={styles.register_modal_right_content_button}>
              <CustomButton
                variant="contained"
                size="medium"
                label={"Sign Up For a Free Demo"}
                onClick={() => openRegistrationForm()}
              />
            </div>
            <div className={styles.register_modal_right_content_button}>
              <CustomButton
                variant="text"
                size="small"
                label={"Do it later!"}
                onClick={() => props.onModalClose()}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </>
  );
};

export default RegisterModalContent;
