import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./footer.module.css";

const Footer = () => {
  const navigate = useNavigate();

  const navigateTo = (link) => {
    navigate(link);
  };

  return (
    <>
      <div className={styles.footer_main_container}>
        <div className={styles.footer_parent_container}>
          <div className={styles.footer_child_left_container}>
              <img src="./header_logo.png" alt="footer logo" className={styles.foolter_img}/>
              {/* <div className={styles.footer_child_left_text}>
                Transform Lives Positively
              </div> */}
          </div>
          <div className={styles.footer_child_right_container}>
            <div className={styles.footer_child_right_container_list_left}>
              <ul>
                <li onClick={() => navigateTo("/")}>Home</li>
                <li onClick={() => navigateTo("/about")}>About Me</li>
                <li onClick={() => navigateTo("/services")}>Services</li>
              </ul>
            </div>
            <div className={styles.footer_child_right_container_list_middle}>
              <ul>
                <li onClick={() => navigateTo("/programs")}>Programs</li>
                <li onClick={() => navigateTo("/contact")}>Contact</li>
                {/* <li onClick={() => navigateTo("/upcoming-events")}>Upcoming events</li> */}
              </ul>
            </div>
            {/* <div className={styles.footer_child_right_container_list_right}>
              <ul>
                <li>MEMBER LOGIN</li>
                <li>PRIVACY POLICY</li>
                <li>TERMS OF SERVICES</li>
                <li>TABLE OF CONTENTS</li>
              </ul>
            </div> */}
          </div>
        </div>
        <div className={styles.footer_copyright_text_container}>
          <div className={styles.footer_copyright_text}>
            Copyright @ Elevate with Jyoti Goyal, All rights reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
