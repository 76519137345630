import React from "react";
import styles from "./services.module.css";

const Services = () => {
  return (
    <div className="main-container">
      <div className="title">My Services</div>
      <div>
        <img src={"./title_underline.png"} alt="underline title img" />
      </div>

      <div>
        <div className={styles.left_align_content}>
          <div className={styles.service_img_container}>
            <img
              src="./training_and_workshop.jpeg"
              alt="service img"
              className={styles.service_img}
            />
          </div>
          <div className={styles.service_desc_container}>
            <div className={styles.service_title}>Trainings & Workshops</div>
            <div className={styles.service_desc}>
              This is Ideal for every individual who is looking for motivation,
              achieving goals and create a Breakthrough in Life. Through these
              Workshops - Online & Offline, I will help you add new dimensions
              in your life, widen your vision and look at the world through your
              own lens in a different yet positive way. These workshops range
              from topics like Health & Wellness, Goal Achievements, Trainer the
              Trainer Programs, Health & Wellness, Communication & Presentation
              Skills, Time Management, Leadership Skill Development, Team
              Building, etc.
            </div>
          </div>
        </div>

        <div className={styles.right_align_content}>
          <div className={styles.service_desc_container}>
            <div className={styles.service_title}>ONE - ON - ONE COACHING</div>
            <b>Design Your own Life … My Life - My Rules</b>
            <div className={styles.service_desc}>
              From relationships, career, finding your passion to how you feel
              first thing in the morning, I create customised programs that help
              you take control over your life and maximise your potential. All
              you have to do is, meet me , have a chit chat and see if our
              frequencies resonate at the same level. Then if you're ready for
              me to help you master the art of designing your life, we'll start
              one on one coaching:
              <ul>
                <li>4 sessions per month</li>
                <li>45 min sessions</li>
                <li>A minimum of 3 months is recommended</li>
              </ul>
            </div>
          </div>
          <div className={styles.service_img_container}>
            <img
              src="./one_to_one.jpeg"
              alt="service img"
              className={styles.service_img}
            />
          </div>
        </div>

        <div className={styles.left_align_content}>
          <div className={styles.service_img_container}>
            <img
              src="./corporate_workshop.jpeg"
              alt="service img"
              className={styles.service_img}
            />
          </div>
          <div className={styles.service_desc_container}>
            <div className={styles.service_title}>Corporate Workshops</div>
            <div className={styles.service_desc}>
              I provide training to Corporates too, to help business realize
              their visions and business goals. The customized workshops support
              business to align their employees with their annual goals and thus
              increase their productivity. These Sessions will help your
              employees and therefore your esteemed Organization to get ALIGNED
              WITH THEIR HIGHER SELF so one can go from reacting to responding
              to situations Specially designed sessions on Health & Wellness,
              Law of Attraction and Manifestation will further help to SET CLEAR
              BOUNDARIES around people who drain your energy And together this
              will INCREASE YOUR MOTIVATION for living LIFE, CREATE A HAPPIER
              YOU AND THUS INCREASE YOUR EFFICIENCY...
            </div>
          </div>
        </div>

        <div className={styles.right_align_content}>
          <div className={styles.service_desc_container}>
            <div className={styles.service_title}>Motivational Speaker</div>
            <div className={styles.service_desc}>
              My Specially Designed Program - Tathaastu - Creating the “So Be
              it” Blissful Life : has helped thousands of people realise their
              potential and passion. This beautifully crafted workshops, had
              aided people to meet themselves and find their long lost deep
              desires of life that would ignite their spark and support them to
              embark on the journey of fulfilment, peace and abundance. Through
              this talk I have enabled people to fight the resistances in their
              life and create a Breakthrough in life by following the 8 - Step
              Process and the Break through Principles, that would not only
              align their thought process with their goals but also help them
              manifest a Life of Dreams and Desires, that was once their Distant
              Wish.
            </div>
          </div>
          <div className={styles.service_img_container}>
            <img
              src="./motivational_speaker.jpeg"
              alt="service img"
              className={styles.service_img}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
