import React from "react";
import styles from "./home.module.css";

const Subscribe = () => {
  return (
    <>
      <div className={styles.subscribe_section}>
        <div className={styles.subscribe_container}>
          <div className={styles.subscribe_child_container_left}>
            <img src={"./microphone.png"} alt="subscribe left img" className={styles.subscribe_child_container_left_img}/>
          </div>
          <div className={styles.subscribe_child_container_right}>
            {/* <div className={styles.subscribe_child_container_right_main_text}>Transform Lives Positively</div> */}
            <div className={styles.subscribe_child_container_right_sub_text}>I am on a mission to help people Form a Connect with their OWNSELF, Increase Their Motivation For Living Life, Craft a Roadmap to Achieve every Life Goal and  Create A HAPPPIER CONTENDED PERSON.</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
