import React, { useEffect, useState } from "react";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material";
import Header from "components/common/header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "components/main/home/home";
import theme from "./theme";
import Footer from "components/common/footer";
import About from "components/main/about/about";
import Benefit from "components/main/benefit/benefit";
import Contact from "components/main/contact/contact";
import Programs from "components/main/programs/programs";
import Services from "components/main/services/services";
import UpcomingEvents from "components/main/upcomingEvents/upcomingEvents";
import CustomModal from "components/shared/ui/customModal";
import RegisterModalContent from "components/common/registerModalContent";

function App() {
  const THEME = createTheme({
    typography: {
      fontFamily: `'Fjalla One', sans-serif`,
      fontSize: 18,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });
  const [openRegisterModal, SetOpenRegisterModal] = useState(false);

  useEffect(() => {
    SetOpenRegisterModal(true);
  }, []);

  const onRegisterModalClose = () => {
    SetOpenRegisterModal(false);
  };

  return (
    <>
      <ThemeProvider theme={THEME}>
        <div className="App">
          <CustomModal
            maxWidth={"md"}
            openModal={openRegisterModal}
            onModalClose={() => onRegisterModalClose()}
          >
            <RegisterModalContent onModalClose={() => onRegisterModalClose()} />
          </CustomModal>

          <Router>
            <Header>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/benefit" element={<Benefit />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/programs" element={<Programs />} />
                <Route path="/services" element={<Services />} />
                <Route path="/upcoming-events" element={<UpcomingEvents />} />
              </Routes>
            </Header>
            <Footer />
          </Router>
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
