import CustomButton from "components/shared/form/customButton";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./home.module.css";

const AboutMe = () => {
  const navigate = useNavigate();

  const goToAboutMe = () => {
    navigate("/about");
  };

  return (
    <>
      <div className={styles.about_me_container}>
        <div className={styles.about_me_child_container_left}>
          <div className={styles.about_me_child_container_left_main_img}>
            <img src="./mountain.png" alt="about me left main img" className={styles.about_me_left_img}/>
          </div>
          <div className={styles.about_me_child_container_left_jyoti_img}>
            <img src="./aboutMeJyotiOld.png" alt="about me left jyoti img" className={styles.about_me_left_jyoti_img}/>
          </div>
        </div>
        <div className={styles.about_me_child_container_right}>
          <div className={styles.about_me_child_container_right_content}>
            <div className={styles.about_me_child_container_right_heading}>
              ABOUT ME
            </div>
            <div>
              <img src={"./title_underline.png"} alt="underline title img" />
            </div>
            <div className={styles.about_me_child_container_right_desc}>
              I come from a traditional Hindu Agrawal Family and live in the
              Diamond City of Surat. I always wanted a simple life. So for a 9-5
              job, I completed my Bachelor of Engineering. I wanted to get
              married into another traditional family and I was blessed to find
              my soul mate in form of Manjit, who was a perfect, successful and
              ambitious Man who gave me a life of love, independence and luxury.
              Once I became a mother, I dreamt of being a Perfect Home Maker. To
              achieve this, I took a sabbatical in the disguise of taking care
              of my only Son, once he turned 2. I also wanted one holiday in a
              year. These simple things made me believe that I was living my
              life of Dreams. Yet, I always Felt that something was missing and
              was Hollow from within.
            </div>
            <div className={styles.about_me_child_container_right_button}>
              <CustomButton
                variant="contained"
                size="medium"
                label={"LEARN MORE"}
                onClick={() => goToAboutMe()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutMe;
