import React from "react";
import styles from "./home.module.css";

const Enthusiasm = () => {
  return (
    <>
      <div className={styles.enthusiasm_container}>
        <div className={styles.enthusiasm_child_container_left}>
          <div className={styles.enthusiasm_child_container_left_main_img}>
            <img src="./group.png" alt="enthusiasm left group img" className={styles.enthusiasm_left_img}/>
          </div>
          <div className={styles.enthusiasm_child_container_left_count}>
            <div>
              <div
                className={styles.enthusiasm_child_container_left_count_number}
              >
                5+
              </div>
              <div
                className={styles.enthusiasm_child_container_left_count_title}
              >
                CORPORATES
              </div>
            </div>
            <div>
              <div
                className={styles.enthusiasm_child_container_left_count_number}
              >
                10+
              </div>
              <div
                className={styles.enthusiasm_child_container_left_count_title}
              >
                CLASSES
              </div>
            </div>
          </div>
        </div>
        <div className={styles.enthusiasm_child_container_right}>
          <div className={styles.enthusiasm_child_container_right_content}>
            <div className={styles.enthusiasm_child_container_right_heading}>
              FIND YOUR ENTHUSIASM
            </div>
            <div
              className={styles.enthusiasm_child_container_right_main_heading}
            >
              A good coach makes sure that they have the right incentive before
              taking on a client
            </div>
            <div>
              <img src={"./title_underline.png"} alt="underline title img" />
            </div>
            <div className={styles.enthusiasm_child_container_right_desc}>
              I am on a mission to help people Form a Connect with their
              OWNSELF, Increase Their Motivation For Living Life , Craft a
              Roadmap to Achieve every Life Goal and Create A HAPPPIER CONTENDED
              PERSON Come along, hop in, as I invite you to Experience a Journey
              of Awakening, to becoming your most CONFIDENT and RADIANT SELF!
              Are You Ready …. ? SO let’s Hit It …. !!!
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Enthusiasm;
