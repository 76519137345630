import React from "react";
import styles from "./about.module.css";

const About = () => {
  return (
    <div className="main-container">
      <div className="title">About Me</div>
      <div>
        <img src={"./title_underline.png"} alt="underline title img" />
      </div>
      <div>
        <div className={styles.about_me_section}>
          <div className={styles.about_me_section_text}>
            To the external world, I lived like a Queen and every woman wanted
            to step into my shoe. But deep inside, I knew that I wasn't born
            just to be an obedient Wife, a good mother or a Perfect Home Maker.
            I was always complimented wonderfully by my family, friends,
            colleagues and everyone around me, on my communication skills.
            People would tell me that they loved listening to me. I was in
            complete awe when I was invited by my associates or friends to
            deliver trainings and workshops in their organization.
          </div>
          <div className={styles.about_me_section_image_container}>
            <img
              src={"./about_me/jyoti_1.jpeg"}
              alt="about me img"
              width={"50%"}
              className={styles.about_me_image}
            />
          </div>
        </div>
        <div className={styles.about_me_section}>
          <div className={styles.about_me_section_image_container}>
            <img
              src={"./about_me/jyoti_2.jpeg"}
              alt="about me img"
              width={"70%"}
              style={{ borderRadius: "20px" }}
            />
          </div>
          <div className={styles.about_me_section_text}>
            Slowly, it became a passion for me to coach people and help them. I
            started looking for jobs in Corporates, Consultancies, institutes,
            etc to find a job as a Trainer to utilize my skills. However, I
            never got what I was looking for. Years went by, and I slowly build
            my profile in the Human Resource field, thinking that someday, I
            will get to Train people as an HR professional.
          </div>
        </div>
        <div className={styles.about_me_section}>
          <div className={styles.about_me_section_text}>
            It was in 2021, post COVID that I had a light bulb moment while
            attending a workshop from a renowned Coach - Puja Puneet, who is my
            mentor and Coach. It just clicked that I do not have to depend on
            any other institute to carry out my Passion. And that was the day
            that my venture - Elevate with Jyoti Goyal was born…! I knew what I
            had to go … But I was not sure - How to do it.
          </div>
          <div className={styles.about_me_section_image_container}>
            <img
              src={"./about_me/jyoti_3.jpeg"}
              alt="about me img"
              width={"70%"}
              style={{ borderRadius: "20px" }}
            />
          </div>
        </div>
        <div className={styles.about_me_section}>
          <div className={styles.about_me_section_image_container}>
            <img
              src={"./about_me/jyoti_4.jpeg"}
              alt="about me img"
              width={"70%"}
              className={styles.about_me_image}
            />
          </div>
          <div className={styles.about_me_section_text}>
            However, once the decision was made, the Universe opened portals for
            me to show me the right path and connecting me with the right
            people, who have taught me and guided me in this journey of becoming
            my RADIANT SELF. Today, as a Life Coach, I am on a mission to help
            people, get their LIGHT BULB moment early in their life so that they
            can follow their PASSION, take informed decision, receive the right
            guidance and achieve their Goals to build a life of their DREAMS AND
            DESIRES.
          </div>
        </div>
      </div>
      <div className="title">My Mission </div>
      <div>
        <img src={"./title_underline.png"} alt="underline title img" />
      </div>
      <div>
        I strongly believe that every individual is an institution in itself who
        has its own unique culture & need on people front. 'I care' about my
        client's culture, goals and brand. The prime focus is to provide value
        added programs in form of trainings, workshops, seminars and webinars to
        create a Connect between the People, thereby INCREASE THEIR MOTIVATION
        for living LIFE , CREATE A HAPPPIER AND PERSON and THUS INCREASE THEIR
        EFFICIENCY to align them with their goals, practices.
      </div>
    </div>
  );
};

export default About;
