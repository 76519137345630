import React from "react";
import styles from "./programs.module.css";

const programList = [
  {
    imageURL: "./breakthrough_mindset.png",
    title: "Breakthrough Mindset",
    contents: [
      "Millionaire Mindsets",
      "Vision Boards and their importance",
      "Affirmations in Life",
      "The DESIGN Mantra",
      "Root Cause Analysis of Problems",
      "How to have a Solution Mindset from a Problem Mindset Stories, Belief Systems, and Life Hacks",
    ],
  },
  {
    imageURL: "./tathaastu.png",
    title: 'Tathaastu - Creating the "So Be It" Blissful Life',
    contents: [
      "Motivational Workshop",
      "Breakthrough Principles of Life",
      "The Resistance of Life - How to fight it",
      "The 8-step process for A rock-solid life",
      "Hacks to design a life of dreams & desires",
      "Law of Attraction & Manifestation Tools Power-packed session to identify passion",
      "Reigniting the spark of life",
      "Lots of engagement activities, fun games, and stories...",
    ],
  },
  {
    imageURL: "./leadership.png",
    title: "Leadership & Mentoring",
    contents: [
      "Leadership stories & world-class examples Skills and strengths of leaders",
      "How leaders are different",
      "The Dragonfly effect",
      "Importance of Upskilling",
      "Setting long-term and short-term directions Optimization of resources",
      "Work-Life Balance",
      "Micro and Macro Management",
      "Handling chaos in teams",
      "Team Moral Improvement",
      "Communication and Motivation",
      "Alignment with Business & Team",
      "Creating a difference through Coaching & Mentoring The Star Fish Story",
    ],
  },
  {
    imageURL: "./diva_power.png",
    title: "Divapower",
    subTitle: "Unleash the power within - A motivational + Lifestyle + Life Design Workshop specially for Women Employees",
    contents: [
      "The Passion test - how to find your passion",
      "Develop a Victim to Victory Attitude",
      "Learning to Take 100% Responsibility for your life and Actions",
      "To connect with your DIVINE FEMININE",
      "Overcoming the Limiting Beliefs",
      "Learning to set Goals and the Life Hacks to Achieve them Few Healing Techniques to Balance our Energies",
      "Power of Affirmations & Visualizations",
      "How to ALIGN WITH YOUR HIGHER SELF so you can go from 'Reacting' to 'Responding' to situations",
      "How to manage your GUILT and ANGER",
      "To SET CLEAR BOUNDARIES around people who drain your energy",
      "To INCREASE YOUR MOTIVATION for living LIFE."
    ],
  },  
  {
    imageURL: "./time_management.png",
    title: "Time Management - Take Charge Of Your Time",
    contents: [
      "3 core ideas to change the way you look at the time",
      "Stop feeling guilty about the time wasted and start using it wisely",
      "Find the value of your time",
      "A 10-day exercise on how to manage your time and be more productive"
    ],
  },
  {
    imageURL: "./sexual_harassment.png",
    title: "Sexual Harassment Prevention",
    contents: [
      "Identifying gender issues & Understanding sexual harassment",
      "Its History and Formulation",
      "Women Empowerment",
      "Initiatives Taken Across the World",
      "The Sexual Harassment Act, 2013",
      "Its Prevention & Prohibition with Redressal Procedure and Mechanism",
      "Case studies & Fun Activities"
    ],
  },
  {
    imageURL: "./health.png",
    title: "Health & Wellness",
    contents: [
      "Multi dimensions of Wellness",
      "Difference between Health & Wellness",
      "What is Resistance & How to overcome it for Holistic Wellness",
      "Secrets to attain Amazing Relationship Status with one and All",
      "A pure Scientific methodology to heal yourself",
      "Healing Techniques: Aura Cleansing, Chakra Healing, Ho Opono Opono Techniques, Cosmic Healing & Meditation, Trauma Healing, Manifestation, and many more (The number of Healing Techniques practiced will depend on the length of the workshop.)",
      "Work-Life Balance",
      "Life Mantras & Understanding your Demons",
      "Setting the Right Energy by Zumba Dance & Sound Healing Meditation",
      "Fun Sheets: My Fears, My Desires, I AM, Gratitude, How Come"
    ],
  },
  {
    imageURL: "./team_building.png",
    title: "Team Building",
    contents: [
      "What is Team Building - Its importance, process, and consequences",
      "Aligning goals",
      "Building effective relationships",
      "5-step process to create a high-performing team Attributes of highly successful teams",
      "Team involvement for effective work",
      "How to develop a teamwork culture",
      "Trust & Communication",
      "Uplifting team spirit - Together everyone achieves more Team motivation",
      "Team building exercises and engagement activities"
    ],
  },
];

const Programs = () => {

  return (
    <>
      <div className="main-container">
        <div className="title">Programs</div>
        <div>
          <img src={"./title_underline.png"} alt="underline title img" />
        </div>
        <div className={styles.programs_main_container}>
          {programList.map((program) => (
            <div key={program.title} className={styles.programs_content}>
              <div className={styles.programs_header_section}>
                <div className={styles.program_img_container}>
                  <img
                    src={program.imageURL}
                    alt="program img"
                    className={styles.program_img}
                  />
                </div>
                <div className={styles.programs_header}>{program.title}
                <br/>
                {program.subTitle ? <div className={styles.programs_sub_header}>{program.subTitle}</div> : null}
                </div>
              </div>
              <div>
                <ul>
                  {program.contents.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Programs;
