import React from "react";
import styles from "./upcomingEvents.module.css";

const UpcomingEvents = () => {
  return (
    <>
      <div className="main-container">
        <div className="title">Upcoming events</div>
        <div>
          <img src={"./title_underline.png"} alt="underline title img" />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default UpcomingEvents;
