import React from "react";
import Dialog from "@mui/material/Dialog";

const CustomModal = (props) => {

  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    } else {
      props.onModalClose();
    }
  }

  return (
    <>
      <Dialog
        fullWidth = {true}
        maxWidth={props.maxWidth}
        open={props.openModal}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: '#e8e6e6',
          },
        }}
      >
        {props.children}
      </Dialog>
    </>
  );
};

export default CustomModal;
