import React from "react";
import AboutMe from "./aboutMe";
import HomeBanner from "./homeBanner";
import OrgranizationList from "./organizationList";
import MyProgram from "./myProgram";
import MyService from "./myService";
import Story from "./story";
import Enthusiasm from "./enthusiasm";
import Benefit from "./benefit";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <OrgranizationList />
      <AboutMe />
      <MyProgram />
      <MyService />
      <Story />
      <Enthusiasm />
      <Benefit />
    </>
  );
};

export default Home;
