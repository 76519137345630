import React from "react";
import styles from "./benefit.module.css";

const Benefit = () => {
  return (
    <div className="main-container">
      <div>
        <div className="title">Who can benefit?</div>
        <div>
          <img src={"./title_underline.png"} alt="underline title img" />
        </div>
        <div>
          <ul>
            <li>
              A company that wants harmony between employees & departments.
            </li>
            <li>
              Anyone who wants to gain knowledge to form the right attitude to
              expand their skill sets.
            </li>
            <li>
              Those who are looking for a direction in life, find a passion and
              achieve financial freedom.
            </li>
            <li>
              People who want higher levels of energy and productivity and
              design a life of Fulfillment and Abundance.
            </li>
            <li>
              People who have high stress, that in turn affects their life,
              relationships and work.
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div className="title">Why Me?</div>
        <div>
          <img src={"./title_underline.png"} alt="underline title img" />
        </div>
        <div>
          <ul>
            <li>Qualitative delivery of content in productive way.</li>
            <li>
              It's not just Work for me, it's a Passion fueled by Humanity.
            </li>
            <li>
              Higher Productivity, Positivity, Abundant Life and Clarity of
              Goals.
            </li>
            <li>I provide an Experience that Count.</li>
          </ul>
        </div>
      </div>

      <div>
        <div className="title">
          What will the attendees walk away with?
        </div>
        <div>
          <img src={"./title_underline.png"} alt="underline title img" />
        </div>
        <div>
          <ul>
            <li>Awakening to their true potential.</li>
            <li>Clarity on their goals & a road map .</li>
            <li>
              An interactive and entertaining experience that inspires them.
            </li>
            <li>Freedom from limiting factors.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Benefit;
