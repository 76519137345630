import CustomButton from "components/shared/form/customButton";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./home.module.css";

const Benefit = () => {
  const navigate = useNavigate();

  const goToBenefit = () => {
    navigate("/benefit");
  };
  return (
    <>
      <div className={styles.benefit_container}>
        <div className={styles.benefit_text_container}>
          <div className={styles.benefit_heading}>How can I help You?</div>
          <div className={styles.benefit_main_heading}>
            From Self-building to goal-setting to energy boosters, my
            interactive session will spark new levels of both positivity and
            productivity in Your Own SELF - I help you Create BreakThrough from
            BreakDown… !!!
          </div>
          <div>
            <img
              src={"./benefits.png"}
              alt="underline title img"
              width={"100%"}
            />
          </div>
        </div>

        <div className={styles.benefit_button}>
          <CustomButton
            variant="contained"
            size="medium"
            label={"LEARN MORE"}
            onClick={() => goToBenefit()}
          />
        </div>
        <div className={styles.benefit_motivational_text}>
          The only man who never makes mistakes is the man who never does
          anything.
        </div>
      </div>
    </>
  );
};

export default Benefit;
