import CustomButton from "components/shared/form/customButton";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./home.module.css";

const MyService = () => {
  const navigate = useNavigate();

  const goToServices = () => {
    navigate("/services");
  };

  return (
    <>
      <div className={styles.my_service_container}>
        <div className={styles.my_service_text_container}>
          <div className={styles.my_service_heading}>MY SERVICES</div>
          <div className={styles.my_service_main_heading}>
            Elevating your employees with Jyoti Goyal
          </div>
          <div>
            <img src={"./title_underline.png"} alt="underline title img" />
          </div>
        </div>
        <div className={styles.my_service_options_container}>
          <div className={styles.my_service_option}>
            <div className={styles.my_service_option_img}>
              <img
                src={"./Trainings_Workshops.png"}
                alt="my service option img"
              />
            </div>
            <div className={styles.my_service_option_title}>
              Trainings & <br />
              Workshops
            </div>
          </div>
          <div className={styles.my_service_option}>
            <div className={styles.my_service_option_img}>
              <img
                src={"./ONE_ON_ONE_COACHING.png"}
                alt="my service option img"
              />
            </div>
            <div className={styles.my_service_option_title}>
              ONE - ON - ONE <br />
              COACHING
            </div>
          </div>
          <div className={styles.my_service_option}>
            <div className={styles.my_service_option_img}>
              <img
                src={"./Corporate_Workshops.png"}
                alt="my service option img"
              />
            </div>
            <div className={styles.my_service_option_title}>
              Corporate <br />
              Workshops
            </div>
          </div>
          <div className={styles.my_service_option}>
            <div className={styles.my_service_option_img}>
              <img
                src={"./Motivational_Speaker.png"}
                alt="my service option img"
              />
            </div>
            <div className={styles.my_service_option_title}>
              Motivational <br />
              Speaker
            </div>
          </div>
        </div>
        <div className={styles.my_service_button}>
          <CustomButton
            variant="contained"
            size="medium"
            label={"LEARN MORE"}
            onClick={() => goToServices()}
          />
        </div>
      </div>
    </>
  );
};

export default MyService;
