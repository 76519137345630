import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ContainedButton = styled(Button)(({ theme }) => ({
  color: "#FFF",
  backgroundColor: "#E55E4B",
  "&:hover": {
    color: "#E55E4B",
    backgroundColor: "#FFF",
  },
}));

const TextButton = styled(Button)(({ theme }) => ({
  color: "#000",
  "&:hover": {
    color: "#000",
  },
}));

const getButton = (buttonProps) => {
  if(buttonProps.variant === 'contained') {
    return <ContainedButton variant={buttonProps.variant} size={buttonProps.size} onClick={() => {buttonProps.onClick()}}>{buttonProps.label}</ContainedButton>
  } else if (buttonProps.variant === 'text') {
    return <TextButton variant={buttonProps.variant} size={buttonProps.size} onClick={() => {buttonProps.onClick()}}>{buttonProps.label}</TextButton>
  } else {
    return null
  }
}

const CustomButton = (props) => {
  return (
    <>
    {
      getButton(props)
    }
    </>
  );
};

export default CustomButton;
